import React, {useContext, useEffect, useState} from 'react'
import {useLocation, useNavigate} from "react-router-dom";
import {CartContext} from "../../contextApi/CartContext/CartContext";
import {AuthContext} from "../../contextApi/AuthContext/authContext";
import styles from "./CheckoutScreen.module.css";
import {fetchFeatureTogglesList} from "../../api/endpoint";
import {createElement, loadAirwallex} from "airwallex-payment-elements";
import {notyf} from "../../notyf";
import classNames from "classnames";
import SkyButton, {ButtonSize} from "../../components/base/SkyButton/SkyButton";
import checkoutImage from "../../images/checkout-image.png";

const CheckoutScreen = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const {cartProducts, total, totalWithFee, paymentGatewayFee} = useContext(CartContext);
  const {accessToken} = useContext(AuthContext);
  const [elementShow, setElementShow] = useState(false);
  const [step, setStep] = useState<number>(1);
  const [env, setEnv] = useState<"demo" | "prod">("demo");

  const navigate = useNavigate();
  const intent_id = queryParams.get("paymentIntentId");
  const clientSecret = queryParams.get("clientSecret");
  const currency = queryParams.get("currency");

  if (!intent_id || !clientSecret || !currency) return null;

  useEffect(() => {
    if (cartProducts.length === 0) {
      navigate("/recharge");
      return;
    }

    if (accessToken)
      fetchFeatureTogglesList().then((featureToggles) => {
        setEnv(
          featureToggles.filter((ft) => ft.name == "IS_PROD_ENV")[0].value
            ? "prod"
            : "demo"
        );
      });

    const loadDropInElement = async () => {
      try {
        await loadAirwallex({
          env: env,
          origin: window.location.origin,
        });

        const element = createElement("fullFeaturedCard", {
          intent_id: intent_id,
          client_secret: clientSecret,
          mode: "payment",

        });

        element?.mount("fullFeaturedCard");
      } catch (err) {
        notyf.error("Something went wrong");
      }
    }
    loadDropInElement();

    const onReady = (event: CustomEvent): void => {
      setElementShow(true);
    }

    const onSuccess = (event: CustomEvent): void => {
      navigate(`/recharge/confirmation?id=${intent_id}`)
    }

    const onError = (event: CustomEvent): void => {
      navigate(`/recharge/confirmation?id=${intent_id}`)
    }

    const domElement = document.getElementById("fullFeaturedCard");
    domElement?.addEventListener("onReady", onReady as EventListener);
    domElement?.addEventListener("onSuccess", onSuccess as EventListener);
    domElement?.addEventListener("onError", onError as EventListener);

    return () => {
      domElement?.removeEventListener("onReady", onReady as EventListener);
      domElement?.removeEventListener("onSuccess", onSuccess as EventListener);
      domElement?.removeEventListener("onError", onError as EventListener);
    };
  }, [location]);

  return (
    <div className={styles.checkoutContainer}>
      <div className={styles.checkoutWrapperContainer}>
        <div className={styles.imageContainer}>
          <img
            src={checkoutImage}
            alt="checkout-image"
            className={styles.checkoutImage}
          />
        </div>
        <div className={styles.checkoutSummary}>
          <div className={styles.checkout}>
            <div className={styles.header}>
              <h2 className={styles.headerTitle}>Checkout</h2>
            </div>

            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>Order Summary</h3>
              <div>
                {cartProducts.map((product, index) => (
                  <div key={index} className={styles.cartProduct}>
                    <div className={styles.cartProductText}>
                      {product.name} x {product.quantity}
                    </div>
                    <div>
                      ${(product.price).toFixed(2)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.section}>
              <div className={styles.summaryRow}>
                <span className={styles.summaryTotal}>Total</span>
                <span className={styles.summaryTotal}>
                ${(total).toFixed(2)}
              </span>
              </div>
              <div className={styles.gatewayFee}>
                <span>Payment Gateways Fee (5%)</span>
                <span>
                ${(paymentGatewayFee).toFixed(2)}
              </span>
              </div>
            </div>
            <div className={styles.summaryRow}>
              <span className={styles.grandTotal}>Grand Total</span>
              <span className={styles.grandTotal}>
              ${(totalWithFee).toFixed(2)}
            </span>
            </div>

            {step === 1 && (
              <div className={styles.paymentButton}>
                <SkyButton
                  size={ButtonSize.SMALL}
                  onClick={() => setStep(2)}
                  disabled={!elementShow}
                  text="Proceed"
                />
              </div>
            )}
          </div>
          <div className={classNames(styles.cartContainer,
            step === 2 ? "" : styles.none
          )}
          >
            <div
              id="fullFeaturedCard"
              className={step === 2 ? styles.block : styles.none}
            ></div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default CheckoutScreen