import React, {useEffect, useState} from 'react';
import styles from "./PrivacyPolicy.module.css";
import {ChevronUp} from "lucide-react";
import logo from "../../images/pegionlogo.png";

const PrivacyPolicy = () => {
  const [showScrollTop, setShowScrollTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollTop(window.scrollY > 200);
    }

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({top: 0, behavior: "smooth"});
  }

  const handleScrollToSection = (id: string) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({behavior: "smooth", block: "start"});
    }
  };

  const sections = [
    {
      title: "Introduction",
      description: `Skytel Digicom Privacy Statement
               <br/>
                Skytel Digicom is sensitive to the private nature of the information you provide us over the Internet.
                Our privacy practices are designed to maximise your ability to control the use of your personal, private
                information while simultaneously allowing you to obtain interesting and useful information, products and
                services. This Privacy Policy describes how we treat information received about you when you visit our
                Website and register online for the products and/or services offered to you. This Privacy Policy only
                applies to data collected on-line by Skytel Digicom. Please read our Privacy Policy carefully. By using
                this Website and submitting any personal information, you consent to the collection and use of this
                information by Skytel Digicom, its subsidiaries and affiliates consistent with this Privacy Policy.
                Also, please be aware that Skytel Digicom may change this Privacy Policy from time to time. Such changes
                are effective upon posting and your continued use of our services constitutes your acceptance of our
                updated Privacy Policy. If we are going to use users' personally identifiable information in a manner materially
                different from that stated at the time of collection we will notify users by posting a notice on our Website for 30
                days, so be sure to check back here periodically for any changes.
                <br/><br/>
                This Statement only pertains to our online data collection from our web properties and does not apply to
                other means of data collection.`
    },
    {
      title: "Information - How We Get It?",
      description: `When you register for our products or services, you may be providing personal information to Skytel
                Digicom such as your name, phone number, address, e-mail and other information by which you can be personally
                identified. You may also be asked to provide demographic information such as zip code and age. If you
                purchase products or services on our Site, you will be asked for financial information such as account
                or credit card numbers. We do not knowingly collect personal information from children under 13. We also
                collect usage information from you when you visit us. Website usage information is not typically
                personally identifiable information. It describes how our Website is used and navigated, including the number and
                frequency of visitors to each Web page and the length of their stay. Website usage information also
                includes the domain names of browsers and IP addresses that visit our Website, time of day the Website
                was visited and other non-personally identifiable information.
                <br/><br/>
                Throughout the Website are forms which enable prospective customers, partners, and distributors to
                contact us to learn more about our products and services. The information we collect on these forms is only used
                to facilitate our business relationship with you. It is not used for any other purpose.`
    },
    {
      title: "Cookies Collection",
      description: `Skytel Digicom makes use of browser cookies. We use cookies to enhance your Web viewing experience. A
                cookie is a small identifier, similar to a license plate and consists of data sent by a Web server to a
                Web browser that can later be read by that server. Cookies are used to personalize your viewing
                experience. They are also used to target advertising and marketing offers to your individual interests
                and to avoid showing you duplicate ads. Cookies cannot be used to get data from your hard drive, your
                e-mail or any other personal data about you. You have the ability to set your Internet Preferences to
                notify you whenever you receive a cookie. You may then accept or decline any of our cookies. Should you
                decide to decline a cookie, this may impact the performance of our Web site.
                <br/><br/>
                Skytel Digicom may make use of clear GIFs. Clear GIFs are small (1 pixel x 1 pixel) graphics which are
                included on some of our web site pages and are similar in function to cookies and are often used in
                conjunction with cookies. They are used to track the online movements of visitors to and within our web
                site. We do not tie the information gathered by clear GIFs to your personally identifiable information,
                except when they are placed in our HTML-based e-mails. When used in HTML-based e-mails, clear GIFs allow
                us to know which e-mails have been opened by the recipients.`
    },
    {
      title: "Information - How We Use It",
      description: `Skytel Digicom believes that knowing more about you will enable us to serve you better. It permits us to
                personalize the content and services we offer to you. It also permits us to target advertising for
                products and services to your interests and tastes. Skytel Digicom uses demographic and Web site usage
                information to improve the products and services we offer to you. One of the many services Skytel
                Digicom can offer to you when you register for our products or services is notification concerning new products
                and services available from third-party marketing partners. These notices and offers will target your
                preferences and tastes based on the information collected. Unless you have opted-out of receiving third
                party offers during registration, we reserve the right to share your personal information with third
                parties and you may receive e-mails concerning third party offers. As a publicly traded company, we are
                required to file numerous reports with different administrative bodies. As such, Skytel Digicom may
                provide aggregate statistics about customers, sales and traffic patterns. None of these reports or
                statistics will include personally identifiable information. However, Skytel Digicom reserves the right
                to use personally identifiable information to investigate and help prevent potentially unlawful activity
                that threatens either Skytel Digicom or any company affiliated with Skytel Digicom. Moreover, upon the
                appropriate request of a government agency, law enforcement, court or as otherwise required by law,
                Skytel Digicom may disclose personally identifiable information.`
    },
    {
      title: "Enhanced Data Gathering",
      description: `Our Website uses reputable third parties to serve the advertisements that you see on the Skytel Digicom
                Website. Please be sure to review our third party ad servers' privacy policies as they may follow
                different procedures than our Website. Additionally, in order to provide you with certain features of
                our service, we provide some of your personal information to others. This information is provided under the
                terms of strict confidentiality agreements and your information will not be shared, sold or rented by
                these other parties without your permission.
                <br/><br/>
                Ability to Opt-Out of E-Mails from Skytel Digicom.
                <br/>
                Upon registration for Skytel Digicom products and services, you will receive a welcome e-mail from
                Skytel Digicom introducing you to Skytel Digicom product and service offerings. If you do not wish to receive
                future e-mails from Skytel Digicom, please follow the instructions contained in the e-mail to
                unsubscribe from receiving future e-mails from Skytel Digicom.`
    },
    {
      title: "Corrections and Complaints",
      description: `Skytel Digicom shall honor any e-mail requests sent to the address below from customers to change their
                account information (i.e., name, address or billing information) or otherwise correct inaccurate
                information. Skytel Digicom will gladly correct such information and customers are welcome to verify
                that the appropriate corrections have been made. In addition, if you believe that your personally
                identifiable information is being mishandled in light of this Privacy Policy, please immediately notify Skytel
                Digicom by e-mail at <a href="mailto:webmaster@skytelservices.com">webmaster@skytelservices.com</a>.`
    },
    {
      title: "Security",
      description: `We take great care and use the latest technologies to ensure the security and safety of all your
                transactions with Skytel Digicom. To prevent unauthorized access or disclosure, maintain data accuracy,
                and ensure the appropriate use of information, we have put in place safeguards to secure the information
                we collect online. Our servers use encryption technology to protect the transfer of your credit card
                data to unauthorized parties. When these types of measures are taken, shopping online is safe and secure. We
                use industry-recognized standards in transaction security to protect the information you provide when
                you make a purchase at Skytel Digicom.`
    },
    {
      title: "Links to Other Sites",
      description: `This Web site contains links to other Web sites. Skytel Digicom is not responsible for the privacy
                practices or the content of such Web sites. You are encouraged to review the privacy policies of these
                other Web sites before registering for any products or services from that Web site.`
    },
    {
      title: "Questions/Concerns",
      description: `If you have questions or concerns about any of the statements herein after reading our Privacy Policy,
                we welcome your feedback. You may contact our Customer Service Department at <a
                href="mailto:webmaster@skytelservices.com">webmaster@skytelservices.com</a>.`
    }
  ]

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.containerWrapper}>
          <div className={styles.headerContainer}>
            <div>
              <div className={styles.headerText}>Skytel - Privacy Policy</div>
              <div className={styles.headerDescription}>
                version-1.0.0 {"  "} - {"  "}
                <span>Effective Date-16/11/2024</span>
              </div>
            </div>
            <img
              src={logo}
              alt={"logo"}
              className={styles.logo}
            />
          </div>
          <nav>
            <h2>Table of Contents</h2>
            <ul>
              {sections.map((section) => (
                <li key={section.title}>
                  <button
                    onClick={() => handleScrollToSection(section.title)}
                    className={styles.titleContent}
                    data-testid={"section-content"}
                  >
                    {section.title}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
          <div className={styles.contentContainer}>
            {sections.map((term) => (
              <section
                className={styles.content}
                id={term.title}
                key={term.title}
              >
                <h2 className={styles.title}>
                  {term.title}
                </h2>
                <div
                  className={styles.description}
                  dangerouslySetInnerHTML={{__html: term.description}}
                ></div>
              </section>
            ))}
          </div>
        </div>
      </div>
      {showScrollTop && (
        <button
          className={styles.scrollTopButton}
          aria-label="Scroll to top"
          onClick={scrollToTop}
          data-testid="scroll-to-top"
        >
          <ChevronUp className={styles.icon}/>
        </button>
      )}
    </div>
  )
}
export default PrivacyPolicy
